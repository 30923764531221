<template>
 <navdrop>
  <vsm-menu
    :menu="menu"
    :base-width="380"
    :base-height="400"
    :screen-offset="10"
    element="header"
    @open-dropdown="onOpenDropdown"
    @close-dropdown="onCloseDropdown"
  >
    <template #before-nav>
      <li class="vsm-section vsm-mob-full logo-section">
        <img width="60px" padding="4px"
          src="../../assets/img/logo.svg"
          alt="SEOWebdesign.org"
        >
      </li>
    </template>
    <!--Add a title using the slot:-->
    <!--<template #title="data">{{ data.item.title }}</template>-->
    <template #default="data">
      <component
        :is="data.item.content"
        class="content"
      />
      <component
        :is="data.item.secondary"
        class="content--secondary"
      />
    </template>
    <template #after-nav>
      <a href="/#contact-us">
      <li class="vsm-section vsm-mob-hide rnd--open" @click="onClick">
        Contact us
      </li></a>
      <!--Display mobile menu-->
      <vsm-mob>
        <mobile-content />
      </vsm-mob>
    </template>
    </vsm-menu>
  </navdrop>
</template>

<script>
import AboutPrimary from './components/content/AboutPrimary'
import PortfolioPrimary from './components/content/PortfolioPrimary'
import PortfolioSecondary from './components/content/PortfolioSecondary'
import HorizontalSecondaryContent from './components/content/HorizontalSecondary'
import HorizontalPrimaryContent from './components/content/HorizontalPrimary'
import VerticalContent from './components/content/Vertical'
import MobileContent from './components/content/MobileContent'
import rnd from './components/sciprts/rnd'

/* eslint-disable no-console */

export default {
  components: {
    MobileContent
  },
  data () {
    return {
      menu: [
        { title: 'About', 
          dropdown: 'about', 
          content: AboutPrimary, 
          listeners: { mouseover: this.onMouseOver } },
        
        { title: 'Solutions', 
          element: 'router-link', 
          attributes: { to: '/solutions' }, 
          dropdown: 'solutions', 
          content: HorizontalPrimaryContent, 
          secondary: HorizontalSecondaryContent },
        
        { title: 'Services', 
          dropdown: 'products', 
          content: VerticalContent, 
          element: 'span' },
        
        { title: 'Portfolio', 
          element: 'router-link', 
          attributes: { to: '/portfolio/' }, 
          dropdown: 'portfolio', 
          content: PortfolioPrimary, 
          secondary: PortfolioSecondary },
      ]
    }
  },
  methods: {
    onClick () {
      const links = this.$refs.header.hasDropdownEls
      const link = links[rnd(0, links.length - 1)]

      this.$refs.header.openDropdown(link)
    },
    onMouseOver (evt) {
      console.log('mouse over', evt)
    },
    onOpenDropdown (el) {
      console.log('open dropdown', el)
    },
    onCloseDropdown (el) {
      console.log('close dropdown', el)
    }
  }
}
</script>

<style lang="scss">
@import "./scss/variables.scss";
@import "~vue-stripe-menu/src/scss/index";

$vsm-media: 1500px;

.vsm-dropdown {
  z-index: 10000;
  
}
.vsm-menu {
  position: relative;
  z-index: 10000;
  nav {
    margin: 0 10px;
  }
  ul {
    max-width: $laptop;
    margin: 0 auto;
  }
    
}
.vsm-root {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vsm-section_menu {
  flex: 1 1 auto;
  justify-content: center;
  > * {
    padding: 0 25px;
    font-weight: 500;
    font-family: inherit;
  }
}
.vsm-link {
  color: #6772e5;
  &.vsm-active,
  &:hover {
    color: #32325d;
  }
}
.content {
  padding: 30px;
}
.content--secondary {
  padding: 30px;
}
.rnd--open {
  cursor: pointer;
  user-select: none;
}
</style>