<template>
<div>
  <div id="app">
    <Header></Header>
  </div>

  <section id="web" class="solution">
            <div class="solution-wrapper row align-items-center">
                <div class="icon-wrapper col-lg-5">
                    <figure>
                        <img src="../../assets/img/web-light.svg" alt="Web icon" />
                    </figure>
                </div>
                <div class="body-wrapper col-lg-7">
                    <div class="solution-text">
                        <h3>Web Solutions</h3>
                        <p><strong>Our Design &amp; Build package starts <span class="text-orange">from 199€</span> and includes:</strong></p>
                            <ul>
                                <li>
                                    <i class="fas fa-check text-highlight"></i> Website Conception to deployment
                                </li>
                                <li>
                                    <i class="fas fa-check text-highlight"></i> Mobile Device compatibility
                                </li>
                                <li>
                                    <i class="fas fa-check text-highlight"></i> Web Analytics &amp; On-site SEO
                                </li>
                                <li>
                                    <i class="fas fa-check text-highlight"></i> Completely collaborative approach
                                </li>
                            </ul>

                            <p> <strong >This package can be extended with these <em>optional extras:</em></strong></p>

                            <ul class="extras">
                                <li>
                                    <i class="fas fa-star text-highlight-star"></i>  Basic Webmaster training<strong> - <span class="text-orange">50€&nbsp;once</span></strong>
                                </li>
                                <li>
                                    <i class="fas fa-star text-highlight-star"></i>  Monthly upkeep + annual freshen-up<strong> - <span class="text-orange">5€&nbsp;a&nbsp;month</span></strong>
                                </li>
                                <li>
                                    <i class="fas fa-star text-highlight-star"></i>  Optimized Hosting<strong> - <span class="text-orange">5€&nbsp;a&nbsp;month</span></strong>
                                </li>
                            </ul>
                        <p class="section-lead-in">
                            <strong>We can tailor the package based on your requirements,  <a href="/#contact-us" class="contact-us-link">contact us</a> to discuss it</strong>
                        </p>
                    </div>
                </div>
            </div>
    </section>
    <FooterSection></FooterSection>
</template>

<script>
import Header from "../Header.vue";
import HomeSection from "../HomeSection.vue";
import FooterSection from "../FooterSection.vue";
import Web from "."

export default {
  name: "app",
  components: {
    Header,
    HomeSection,
    Web,
    FooterSection
  },
  data: () => {
    return {
      show: true,
    };
  },

  created() {
    setTimeout(() => {
      this.show = false;
    }, 2000);
  }
};

</script>