<template>
  <div>
    <base-group color="secondary" />
    <base-group color="secondary" />
  </div>
</template>

<script>
import BaseGroup from '../base/Group'

export default {
  components: {
    BaseGroup
  }
}
</script>
