import Vue from 'vue'
import Router from 'vue-router'

import PortfolioSection from "../components/PortfolioSection.vue"
import Landing from '../components/Landing.vue'
import ThankYouSection from '../components/ThankYouSection.vue'
import Solutions from '../components/Solutions.vue'
import Web from '../components/solutions/Web.vue'


Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {path: '/', component: Landing },
    {path: '/portfolio', component: PortfolioSection },
    {path: '/thank-you', component: ThankYouSection },
    {path: '/solutions', component: Solutions },
    {path: '/solutions/web', component: Web },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.path === "/") {
      return { x: 0, y: 0 }
    }
      else if (to.hash) {
        return { selector: to.hash }
    } else if (savedPosition) {
        return savedPosition;
    } else {
      return {x: 0, y: 0}
    }
  },

})
