<template>
  <header class="siteheader" role="banner">
        <scrollactive  class="navbar navbar-expand-md navbar-dark"
          active-class="active" :offset="55" :duration="1500">
            <h1 id="logo" class="shrink">
                <router-link to="/#home" class="navbar-brand scrollactive-item" @click="scrollOrRedirect">
                    <img src="../assets/img/logo.svg" alt="logo">
                    SEO & Webdesign
                </router-link>
            </h1>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsible-navbar" aria-controls="collapsible-navbar" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="collapsible-navbar">
                <ul class="navbar-nav">
                  <li class="nav-item"><a @click="scrollOrRedirect" class="nav-link scrollactive-item" href="#home">Home</a></li>
                  <li class="nav-item"><a @click="scrollOrRedirect" class="nav-link scrollactive-item" href="#solutions">Solutions</a></li>
                  <router-link to="/portfolio" tag="li" class="nav-item"><a @click="navigate" class="nav-link scrollactive-item">Portfolio</a></router-link>
                  <li class="nav-item"><a @click="scrollOrRedirect" class="nav-link scrollactive-item" href="#contact-us">Contact Us</a></li>
                  </ul>
            </div>
        </scrollactive>
    </header>
</template>

<script defer>

import $ from 'jquery'

function scrollToSection (selector) {
  var $section = $(selector)

  if (!$section.length) {
    return
  }
  $('html,body').animate({
    scrollTop: selector === '#home' || selector === '#' ? 0 : $section.offset().top
  }, 150)
}

function closeNavBar () {
  $('.navbar-nav>li>a').on('click', function () {
    $('.navbar-collapse').collapse('hide')
  })
}
export default {
  computed: {
    isHomepage () {
      return this.$route.path === '/'
    },
    isPortfolioPage () {
      $('.team').slick('slickPause')
      $('.teamNav-slide').slick('slickPause')
      return this.$route.path === '/portfolio'
    }
  },
  methods: {
    scrollOrRedirect (e) {
      let href = e.currentTarget.getAttribute('href')
      if (this.isHomepage) {
        e.preventDefault()
      } else {
        this.$router.push({path: '/' + href})
      }
    }
  },
  mounted () {
    scrollToSection(window.location.hash)
    window.location.hash = ''
    closeNavBar()
  },
  metaInfo: {
    meta: [
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'SEO and Webdesign is our passion, we doing it for the sake of practical perfection as GTD and the KISS principle > $services: Webdesign, SEO, SEM, E-Commerce, ERP, CRM, Odoo & Development, get in touch'}
    ]
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.navbar-nav li a.active {
  color:#dedede;
  opacity: 1;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10
}

header.siteheader {
    background: linear-gradient(45deg, #231bbb 0%,#F5AF19 55%)
}

header .navbar {
    max-width: 90%;
    margin: 0 auto
}

@media (min-width: 3840px) {
  header .navbar {
    max-width: 3000px;
  }
}

#logo {
    line-height: 0;
    text-align: center;
    margin: 0;
    padding: 1.5rem 1.5rem 1.5rem 0;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear
}

@media (max-width: 767px) and (orientation: portrait) {
    #logo {
        padding: 0 0.5rem;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear
    }

    .navbar-collapse {
        background-color: #fefffe 85%
    }

    .navbar > .container { max-width: 100%; padding: 0 }
}

@media (max-width: 767px) and (orientation: landscape) {
    #logo {
        padding: 0 0.5rem;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear
    }

    .navbar-collapse {
        background-color: #fefffe 85%
    }

    .navbar > .container { max-width: 100%; padding: 0; }
}

#logo * {
    margin: 0;
    padding: 0;
    line-height: 100%
}

#logo.shrink {
    padding: 0 0.5rem;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear
}

#logo.shrink a img {
    height: 3.13rem;
    width: 3.13rem;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear
}

#logo a {
    color: #fefffe
}

#logo a img {
    height: 6.25rem;
    width: 6.25rem;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear
}

@media (max-width: 767px) and (orientation: portrait) {

    header.siteheader {
         background-color: #fefffe 85%
    }
    #logo a img {
        height: 3.13rem;
        width: 3.13rem;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear
    }

    .navbar-collapse {
        background-color: #fefffe 85%
    }

    .navbar > .container { max-width: 100%; padding: 0; }
}

@media (max-width: 767px) and (orientation: landscape) {

    header.siteheader {
        background-color: #fefffe 85%
    }

    #logo a img {
        height: 50px;
        width: 50px;
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear
    }

    .navbar-collapse {
        background-color: #fefffe 85%
    }

    .navbar > .container { max-width: 100%; padding: 0; }
}

nav.navbar {
    padding: 0.5rem
}

div.navbar-collapse {
    position: relative
}

li.nav-item {
    text-align: center;
    padding: 0 1rem;
    font-family: "Montserrat", sans-serif
}
/*
li.nav-item:hover {
    background: #f7f9e2 40%;
    color: #000000;
    font-weight: 600;
}*/
li.nav-item:selected {
    font-weight: 700;
}
li.nav-item a {
    color: white !important;
    opacity: 0.8;
    transition: 0.4s;
}

li.nav-item.active a,
li.nav-item a:hover,
li.nav-item a:focus {
    opacity: 1;
}
@media only screen and (min-width: 768px) {
    ul.navbar-nav {
        position: absolute;
        right: 0
    }

    .navbar-collapse {
        background-color: greenyellow 90%
    }

    .navbar > .container { max-width: 100%; padding: 0; }
}
</style>
