<template>
  <section v-is-in-view="{
        showIfPartial: true,
        callback: callback }"
           v-bind:class="{ 'contact-highlight': changeBackground, 'contact': !changeBackground }"
           id="contact-us">
    <div class="container">
      <h2 class="text-center">Book a
        <strong>FREE</strong> consultation</h2>
      <br>
      <p class="text-center">
        <div class="center">
          <ul>
            <li>
              <p>
                <i class="fas fa-check text-highlight"></i> You get to ask our experts for advice without making any commitments.</p>
            </li>
            <li>
              <p>
                <i class="fas fa-check text-highlight"></i> It helps us because we get to understand what our clients are thinking about.</p>
            </li>
            <li>
              <p>
                <i class="fas fa-check text-highlight"></i> No SALES people here, just technical experts.</p>
            </li>

          </ul>
        </div>

    </div>
      <p class="text-center">
        <span class="text-blue">Tel: </span>
        <a href="tel:+496934868919">
          <strong>+49 (0)69 / 348 68 919</strong>
        </a>
      </p>

      <div class="row">
        <div class="col-md-8 offset-md-2">
          <form id="contact-form" @submit="submitForm" action="" method="post">
            <div class="row">
              <div class="col-md-12">
                <p class="text-center">
                  <span>Or write us:</span>
                </p>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name"
                         class="sr-only">Name</label>
                  <input required
                         type="text"
                         class="form-control"
                         name="name"
                         id="name"
                         placeholder="Name"
                         v-model="input.name">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email"
                         class="sr-only">Email</label>
                  <input required
                         type="email"
                         class="form-control"
                         name="email"
                         id="email"
                         placeholder="Email"
                         v-model="input.email">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="subject"
                         class="sr-only">Subject</label>
                  <input 
                         type="text"
                         class="form-control"
                         name="subject"
                         id="subject"
                         placeholder="Subject"
                         v-model="input.subject">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="phone"
                         class="sr-only">Phone</label>
                  <input 
                         type="phone"
                         class="form-control"
                         name="phone"
                         id="email"
                         placeholder="Phone"
                         v-model="input.phone">
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="message"
                     class="sr-only">Message</label>
              <textarea required
                        name="message"
                        rows="5"
                        class="form-control"
                        id="message"
                        placeholder="Message"
                        v-model="input.message"></textarea>
            </div>
            <button :disabled="loading"
                    type="submit"
                    class="btn">Send</button>
          </form>
          <div class="alert alert-danger"
               v-if="hasErrors"
               v-text="error"></div>
        </div>
      </div>

  </section>
</template>

<script>
const querystring = require('querystring');

import axios from "axios";

export default {
  data() {
    return {
      input: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: ""
      },
      hasErrors: false,
      error: "",
      loading: false,
      changeBackground: false
    };
  },

  watch: {
    input: {
      handler() {
        this.loading = false;
        this.hasErrors = false;
      },
      deep: true
    }
  },

  methods: {
    callback(element) {
      this.changeBackground = true;
    },

    submitForm(event) {
      event.preventDefault();

      const self = this;
      console.log(this.input)

      self.loading = true;
      let emailReg = /^([a-zA-Z0-9\\.]+)@([a-zA-Z0-9\\-\\_\\.]+)\.([a-zA-Z0-9]+)$/i;
      let input = self.input;

      if (
        input.name === "" ||
        !emailReg.test(input.email) ||
        input.message === ""
      ) {
        self.hasErrors = true;
        self.error = "Please fill in the form correctly.";
      } 
      
      else {
        axios.post("./static/mail.php", querystring.stringify(this.input))

        .then(response => {
            self.$router.push("thank-you");
            self.loading = false;
            this.sent = true;
          })
          .catch(error => {
            self.hasErrors = true;
            self.error =
              "Something went wrong. Please try again some other time";
          });
      }
    }
  }
};
</script>

<style scoped>
.alert {
  margin-top: 30px;
}

.contact-highlight {
  -webkit-transition: all 4s ease-out;
  -moz-transition: all 4s ease-out;
  -o-transition: all 4s ease-out;
  transition: all 4s ease-out;
  background-color:#c6af0f;
  color: #ffffff;
  padding: 6vw 0;
  position: relative;
}

.contact {
  background: #2d7d7d;
  color: white;
  padding: 6vw 0;
  position: relative;
}

@media (max-width: 767px) {
  .contact {
    padding: 5rem 0;
  }
}

@media (max-width: 767px) {
  .contact-highlight {
    padding: 5rem 0;
  }
}
.contact strong {
  color: #004080;
  font-weight: bolder;
}

.contact-highlight strong {
  color: #000000;
  font-weight: bolder;
  border:#000000 4px dashed;
  padding: 1px;
}

.contact p {
  font-size: 2rem;
}

.contact-highlight p {
  font-size: 2rem;
}

@media (max-width: 767px) {
  .contact p {
    font-size: inherit;
  }
}
@media (max-width: 767px) {
  .contact-highlight p {
    font-size: inherit;
  }
}

.contact ul {
  padding-left: 0;
}

.contact-highlight ul {
  padding-left: 0;
}

.contact ul li {
  list-style: none;
  text-align: left;
}

.contact ul li .fa-check {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.contact-highlight ul li .fa-check {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.contact ul li p {
  font-size: 1.5rem;
}

.contact-highlight ul li p {
  font-size: 1.5rem;
}

.contact ul li .fa-check,
.contact ul li p {
  font-size: inherit;
}

.contact-highlight ul li .fa-check,
.contact-highlight ul li p {
  font-size: inherit;
}

.contact form input,
.contact form textarea,
.contact form button {
  border-radius: 0;
}

.contact-highlight form input,
.contact-highlight form textarea,
.contact-highlight form button {
  border-radius: 0;
}

.contact form input {
  height: 2.81rem;
  font-size: 1em;
}

.contact-highlight form input {
  height: 2.81rem;
  font-size: 1em;
}

.contact form button {
  background: #55c027;
  color: white;
  min-width: 100px;
  text-transform: uppercase;
  padding: 0.7em 1.3em;
}

.contact-highlight form button {
  background: #55c027;
  color: white;
  min-width: 100px;
  text-transform: uppercase;
  padding: 0.7em 1.3em;
}

.contact form .alert {
  margin-top: 1.2em;
}

.contact-highlight form .alert {
  margin-top: 1.2em;
}
</style>

