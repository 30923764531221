<template>
  <section id="home" class="align-items-center" router-link to="/home">
    <div class="particles">
      <transition name="fade">
        <vue-particles ref="particles"
                       class=''
                       color=#ffffff
                       :particleOpacity='0.9'
                       :particlesNumber='particlesCount'
                       shapeType='circle'
                       :particleSize="8"
                       :distance="7"
                       linesColor=#ec7259
                       :linesWidth= "2.7"
                       :lineLinked='true'
                       :lineOpacity='0.75'
                       :linesDistance='220'
                       :moveSpeed='1.4'
                       :hoverEffect = 'true'
                       hoverMode='grab'
                       :clickEffect='false'
                       clickMode='push'
                       retina_detect='true'></vue-particles>
      </transition>
    </div>
    <div class="tagline-wrapper">
      <h2 id="tagline">
        <div class="row">
          <p class="top-bottom">this is the way to</p>
        </div>
        <div class="row middle">
          <p>
          <a :href="hrefSolution">
            <vue-typer @typed="typed"
                       :text='["digital","web","mobile","cloud"]'
                       :repeat='Infinity'
                       :shuffle='false'
                       initial-action='typing'
                       :pre-type-delay='70'
                       :type-delay='80'
                       :pre-erase-delay='2000'
                       :erase-delay='300'
                       erase-style='select-all'
                       :erase-on-complete='false'
                       caret-animation='blink'></vue-typer>
          </a>
          </p>
        </div>
        <div class="row">
          <p class="top-bottom">solutions for</p>
        </div>
        <div class="row">
          <vue-typer @typed="typed"
                       :text='["professionals"]'
                       :repeat='0'
                       :shuffle='false'
                       initial-action='typing'
                       :pre-type-delay='120'
                       :type-delay='150'
                       :pre-erase-delay='2000'
                       :erase-delay='300'
                       erase-style='select-all'
                       :erase-on-complete='false'
                       caret-animation='blink'></vue-typer>
         </div>
      </h2>
    </div>
    <a href="#solutions"
       class="learn-more-btn call-to-action">Learn More</a>
  </section>
</template>

<script>
import { VueTyper } from "vue-typer";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    VueTyper,
    FadeTransition
  },
  data: () => {
    return {
      selectedText: "",
      dur: 500,
      particlesCount: 70
    };
  },
  methods: {
    typed: function(typedString) {
      this.selectedText = typedString;
    },
    handleResize: function() {
      let isTablet = window.matchMedia("only screen and (max-width: 1024px)");
      let isMobile = window.matchMedia("only screen and (max-width: 414px)");
      let mobileParticlesCount = 40;
      let tabletParticlesCount = 60;
      if (isMobile.matches) {
        this.particlesCount = mobileParticlesCount;
      } else if (isTablet.matches) {
        this.particlesCount = tabletParticlesCount;
      }
      console.log("particle count: ", this.particlesCount)
    }
  },
  computed: {
    hrefSolution() {
      return `#${this.selectedText}`;
    }
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.handleResize();
      });
    });
  }
  // mounted() {
  //   var x = 1
  //   // while (x < 30) {
  //   //   x++;
  //   //   document
  //   //     .querySelector("canvas.particles-js-canvas-el")
  //   //     .dispatchEvent(new Event("click")); // Fire event
  //   //   console.log("called");
  //   // } var x = 1;
  //   this.$nextTick(function() {
  //     while (x < 50) {
  //       x++;
  //       console.log(        document
  //         .querySelector("canvas.particles-js-canvas-el"))
  //       // document
  //       document
  //  .elementFromPoint(50, 50).click();
  //         // .dispatchEvent(new Event("click")); // Fire event
  //       console.log("called");
  //     }
  //   });
  // }
};
</script>

<style>
#home {
  /* background-size: contain;*/
  color: #fefffe;
  font-family: "Montserrat", sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  margin: 0;
  margin-top: 0px;
  position: relative;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  overflow: hidden;
}
.particles {
  position: absolute;
  /*background: url("/static/bg_theway.jpg") no-repeat left top;  old browsers */
  background: linear-gradient(0deg, #0250c5 0%,#CA6B2C 55%,#2c821c 85%);
  background: linear-gradient(0deg, #0250c5 0%,#CA6B2C 55%,#2c821c 85%);
  background: -webkit-linear-gradient(0deg, #0250c5 0%,#CA6B2C 55%,#2c821c 85%); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(0deg, #0250c5 0%,#CA6B2C 55%,#2c821c 85%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 100%;
  height: 100%;
}
#home:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  height: 3.13rem;
  width: 50%;
  border-left: 3.13rem solid transparent;
  border-bottom: 3.13rem solid #fefffe;
}
#home * {
  margin: 0;
  line-height: 100%;
}
#home div.tagline-wrapper {
  line-height: 1.75ch;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  z-index: 3;
}
@media (min-width: 3840px) {
  #home div.tagline-wrapper {
    max-width: 3000px;
  }
}
#home div.tagline-wrapper h2 {
  font-size: 4.8em;
  font-weight: 700;
  text-align: left;
}
@media (max-width: 991px) and (orientation: landscape) {
  #home div.tagline-wrapper h2 {
    font-size: 12vh !important;
  }
}
#home div.tagline-wrapper h2 ul.slick-dots {
  display: none !important;
}
#home div.tagline-wrapper a {
  display: block;
}
#home div.tagline-wrapper a:link,
#home div.tagline-wrapper a:visited {
  color: #ffffff;
  text-decoration: none;
}
#home div.tagline-wrapper a:hover {
  color: #ffffff;
}
#home div.tagline-wrapper a:active {
  color: #ffffff;
}
#home div.tagline-wrapper a:visited:active {
  color: #ffffff;
}
#home a.learn-more-btn {
  position: absolute;
  bottom: 1.88rem;
  right: 14%;
  z-index: 2;
}
@media (max-width: 767px) and (orientation: portrait) {
  /* #home {
      background-image:url('../assets/img/header-mobile-progressive.jpg');
    } */
  #home div.tagline-wrapper h2 {
    font-size: 3em;
  }
  #home a.learn-more-btn {
    bottom: 5rem;
    right: 20%;
  }
}
#home a.learn-more-btn:link,
#home a.learn-more-btn:visited {
  display: inline-block;
  background-color: #34b95b;
  color: white;
  border-radius: 8px;
  padding: 1rem 3rem;
  margin-top: 0.5rem;
  text-decoration: none;
}
#home a.learn-more-btn:hover {
  background-color: #269f49;
  color: white;
}
#home a.learn-more-btn:active {
  background-color: #228f42;
  color: white;
}
.middle {
  color: white;
  padding: 8px;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;

}
.top-bottom {
   color: white;
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.vue-typer .custom.char.typed {
  color: white;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
}
char custom typed .middle,
.top-bottom {
  display: inline-block;
  padding: 8px;
  word-wrap: break-word;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
div#particles-js {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
