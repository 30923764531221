<template>
  <div id="app">
    <navdrop></navdrop>
    <welcome v-show="show"></welcome>
    <home-section></home-section>
    <main role="main">
      <Solutions></Solutions>
      <Sections></Sections>
      <ContactSection></ContactSection>
      <FooterSection></FooterSection>
    </main>
  </div>
</template>

<script>
import navdrop from "./nav-drop/drop-solutions.vue";
import Welcome from "./Home/Welcome.vue";
import HomeSection from "./HomeSection.vue";
import Solutions from "./Solutions.vue";
import Sections from "./Sections.vue";
import ContactSection from "./ContactSection.vue";
import FooterSection from "./FooterSection.vue";


export default {
  name: "app",
  components: {
    navdrop,
    HomeSection,
    Solutions,
    Sections,
    ContactSection,
    FooterSection
  },
  data: () => {
    return {
      show: true,
    };
  },

  created() {
    setTimeout(() => {
      this.show = false;
    }, 2000);
  }
};

</script>
